<template>
  <mobile-screen :header="true" screen-class="edit-form-screen add-translation">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="translations-header">
        <template v-slot:left>
          <router-link :to="{ name: 'r_translations' }">
            <svg-icon icon="arrow-left"></svg-icon>
          </router-link>
        </template>
        <div class="component-title">
          Add translation
        </div>
      </top-header-menu-wrapper>
    </template>
    <Form
      @submit="submitForm"
      v-slot="{ errors }"
      class="form tiny-input edit-form"
      novalidate
    >
      <div class="form-group input">
        <label class="tiny-label">
          Name
        </label>
        <Field
          name="name"
          as="input"
          type="text"
          rules="required"
          :disabled="submitting"
        />
        <span class="error-message" v-if="errors.name">
          {{ errors.name }}
        </span>
      </div>
      <div class="form-group submit">
        <button class="btn rounded light" type="submit" :disabled="submitting">
          Add translation
        </button>
      </div>
    </Form>
  </mobile-screen>
</template>

<script>
import httpServiceAuth from "@/services/http-service";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { apiEndpoints } from "@/services/constants";
import { mapActions, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "AddTranslation",
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    ...mapState("user", ["userId"])
  },
  methods: {
    ...mapActions("translation", ["getTranslations"]),
    submitForm(values, { resetForm }) {
      if (values) {
        this.submitting = true;
        this.submitMasterData(values, resetForm);
      }
    },
    submitMasterData(values, resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(apiEndpoints.master.translations, values)
        .then(() => {
          resetForm();
          // this.getTranslations();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
